import React from "react";
import { useLocation } from '@reach/router';

import PRIMARYNAV from "../../../data/primaryNav.jsx";
import ELITEKHCPNAV from "../../../data/elitekHcpNav.jsx";
import ELITEKNAV from "../../../data/elitekNav.jsx";
import JEVTANAHCPNAV from "../../../data/jevtanaHcpNav.jsx";
import JEVTANANAV from "../../../data/jevtanaNav.jsx";
import SARCLISAHCPNAV from "../../../data/sarclisaHcpNav.jsx";
import SARCLISANAV from "../../../data/sarclisaNav.jsx";
import NavItem from "../item.jsx";
import MidLinks from "./midLinks.js";
import DropDownBrand from "./dropdownBrand.js";
import BrandLinks from "./brandLinks.js";
import SITELINKS from "../../../data/siteLinks.jsx";
import Logo from "../../../images/logos/careassist-logo-cropped.svg";
import { Link } from "gatsby";
import "../navigation.scss";
import classNames from "classnames";
// import outLink from "../../../images/Unsorted-ASSETS/external-link.svg";


class DesktopNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSite: "",
      activeSection: "Select medication",
      activeNav: null,
      setHomeUrl: ""
    };
  }



  componentDidMount() {
    this.setActive();
  }

  componentDidUpdate() {
    if (this.state.activeSite === "hcp") {
      if (
        this.state.activeSection === "Jevtana" &&
        this.state.activeNav !== JEVTANAHCPNAV
      ) {
        this.setState({
          activeNav: JEVTANAHCPNAV,
        });
      } else if (
        this.state.activeSection === "Sarclisa" &&
        this.state.activeNav !== SARCLISAHCPNAV
      ) {
        this.setState({
          activeNav: SARCLISAHCPNAV,
        });
      } else if (
        this.state.activeSection === "Elitek" &&
        this.state.activeNav !== ELITEKHCPNAV
      ) {
        this.setState({
          activeNav: ELITEKHCPNAV,
        });
      }
    } else if (this.state.activeSite === "patients") {
      if (
        this.state.activeSection === "Jevtana" &&
        this.state.activeNav !== JEVTANANAV
      ) {
        this.setState({
          activeNav: JEVTANANAV,
        });
      } else if (
        this.state.activeSection === "Sarclisa" &&
        this.state.activeNav !== SARCLISANAV
      ) {
        this.setState({
          activeNav: SARCLISANAV,
        });
      } else if (
        this.state.activeSection === "Elitek" &&
        this.state.activeNav !== ELITEKNAV
      ) {
        this.setState({
          activeNav: ELITEKNAV,
        });
      }
    } else if (this.state.activeNav != null) {
      this.setState({
        activeNav: null,
      });
    }
  }

  setActive = () => {
    var activeElement = document.getElementsByClassName("active");

    if (activeElement.length === 0) {
      let location = window.location.href.split("/");
      let filteredLocation = location.filter((value) => {
        return value === "hcp";
      });

      if (filteredLocation.length > 0) {
        activeElement = document.getElementsByClassName("hcp");
        this.setState({
          activeSite: "hcp",
          setHomeUrl: "/hcp"
        });
      } else {
        activeElement = document.getElementsByClassName("patients");
        this.setState({
          activeSite: "patients",
          setHomeUrl: "/"
        });
      }
    }

    let location = window.location.href.split("/");
    let sarclisaLocation = location.filter((value) => {
      return value === "sarclisa";
    });
    // console.log(sarclisaLocation)
    let jevtanaLocation = location.filter((value) => {
      return value === "jevtana";
    });
    // console.log(jevtanaLocation)
    let elitekLocation = location.filter((value) => {
      return value === "elitek";
    });
    // console.log(elitekLocation)

    if (sarclisaLocation.length > 0) {
      this.setState({
        activeSection: "Sarclisa",
      });
    } else if (jevtanaLocation.length > 0) {
      this.setState({
        activeSection: "Jevtana",
      });
    } else if (elitekLocation.length > 0) {
      this.setState({
        activeSection: "Elitek",
      });
    } else {
      this.setState({
        activeSection: "",
      });
    }

    for (let i = 0; i < activeElement.length; i++) {
      var element = activeElement[i];
      while (element.tagName !== "NAV") {
        if (element.tagName === "LI") {
          element.classList.add("active-list-item");
        }
        let currentTab = document.getElementsByClassName("active-list-item")[0];

        if (currentTab) {
          this.setState({
            activeTab: currentTab.classList[0],
          });
        }

        // console.log(this.state.activeTab)

        element = element.parentNode;
      }
    }
    // console.log(this.state.activeSection)
  };

  render() {
    return (
      <div>
        <nav role="navigation" className="navigation top-nav">
          <ul>
            {PRIMARYNAV.map((navItem) => (
              <NavItem
                linkId={navItem.linkId}
                navId={navItem.id}
                navClass={navItem.class}
                image={navItem.image}
                internal={navItem.link.internal}
                content={navItem.content}
                imageSrc={navItem.imageSrc}
                link={navItem.link.path}
                // key={navItem.id}
              />
            ))}
          </ul>
        </nav>

        <nav role="navigation" className="navigation mid-nav">
          <div className={classNames("nav-text-links", { "d-lg-flex": true })}>
            {/*<Link className="patients" to="/">View Patients and Caregivers Site</Link>*/}
            {/*<Link className="hcp" to="/hcp">View US Healthcare Professionals Site</Link>*/}
            {/*<img className="icon" src={outLink}/>*/}
          </div>
          <Link id="careassist-logo" to={this.state.setHomeUrl}>
            <img src={Logo} className="main-logo m-t-20 m-b-10 mobile-m-t-10" alt="CareASSIST logo" />
          </Link>
          <input type="checkbox" className="nav-checkbox" id="nav-toggle" />
          <label className="nav-button" for="nav-toggle">
            <span className="nav-icon"></span>
          </label>

          <div className="nav-bg">
            <DropDownBrand activeTab={this.state.activeTab} />
            <BrandLinks activeNav={this.state.activeNav} />
            <MidLinks />
          </div>

          <DropDownBrand desktopOnly activeTab={this.state.activeTab} />
          <MidLinks desktopOnly />
          <BrandLinks desktopOnly activeNav={this.state.activeNav} />
        </nav>
      </div>
    );
  }
}

export default DesktopNav;