import React from "react";
import { Link } from "gatsby";

const NavItem = (props) => {
  return (
    <li id={props.navId} className={props.navClass} key={props.key}>
        {
        props.internal ? 
        // <Link to={props.link} activeClassName="active" class={props.componentclass} id={props.componentId} target={props.target}>{props.image? <img alt={props.content} src={props.imageSrc} /> : props.content}</Link> :
        <Link id={props.linkId} to={props.link} className={props.componentclass} target={props.target}>{props.image? <img alt={props.content} src={props.imageSrc} /> : props.content}</Link> :
        <a id={props.linkId} href={props.link} className={props.componentclass} target={props.target}>{props.image? <img alt={props.content} src={props.imageSrc} /> : props.content}</a>
        }
    </li>
  );
};

NavItem.defaultProps = {
    internal        : true,
    content         : "",
    link            : "",
    target          : null, 
    componentClass  : "",
    image           : null,
    imageSrc       : ""
  }

export default NavItem;
