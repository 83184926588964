import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import outLink from "../../../images/Unsorted-ASSETS/external-link.svg";
// import user from "../../../images/Unsorted-ASSETS/user.svg"

const MidLink = ({ desktopOnly }) => (
  <>
    {/* {desktopOnly ? (
      ""
    ) : (
      <div
        className={classNames("nav-text-links hcp", {
          "d-lg-flex": desktopOnly,
        })}
      >
        <img className="icon" src={outLink} alt="patient icon" />
        <Link to="/">ENROLL A PATIENT</Link>
      </div>
    )} */}
    {desktopOnly ? (
      <div className="pi-wrapper">
        <div className="pi patient home">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/elitek/elitek.html"
            target="_blank"
            rel="noreferrer"
            id="patient-pi-elitek"
          >
            Prescribing Information
          </a>
          , including Boxed WARNING, for ELITEK<sup>®</sup>{" "}
          (rasburicase).
        </div>
        <div className="pi patient home">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/jevtana/jevtana.html"
            target="_blank"
            rel="noreferrer"
            id="patient-pi-jevtana"
          >
            Prescribing Information
          </a>
          , including Boxed WARNING, and 
          <a
            href="https://products.sanofi.us/Jevtana/Jevtana.html#section-21"
            target="_blank"
            rel="noreferrer"
            id="patient-patient-info-jevtana"
          >
            Patient Information
          </a> for JEVTANA<sup>®</sup>{" "}
          (cabazitaxel) Injection.
        </div>
        <div className="pi patient elitek">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/elitek/elitek.html"
            target="_blank"
            rel="noreferrer"
            id="patient-pi-elitek"
          >
            Prescribing Information
          </a>
          , including Boxed WARNING.
        </div>
        <div className="pi patient jevtana">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/jevtana/jevtana.html"
            target="_blank"
            rel="noreferrer"
            id="patient-pi-jevtana"
          >
            Prescribing Information
          </a>
          ,<br />
          including Boxed WARNING, and{" "}
          <a
            href="https://products.sanofi.us/Jevtana/Jevtana.html#section-21"
            target="_blank"
            rel="noreferrer"
            id="patient-patient-info-jevtana"
          >
            Patient Information
          </a>
          .
        </div>
        <div className="pi patient sarclisa">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/Sarclisa/sarclisa.pdf"
            target="_blank"
            rel="noreferrer"
            id="patient-pi-sarclisa"
          >
            Prescribing Information
          </a>
          ,<br />
          including{" "}
          <a
            href="https://products.sanofi.us/Sarclisa/sarclisa_patient_information.pdf"
            target="_blank"
            rel="noreferrer"
            id="patient-patient-info-sarclisa"
          >
            Patient Information
          </a>
          .
        </div>
        <div className="pi hcp home">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/elitek/elitek.html"
            target="_blank"
            rel="noreferrer"
            id="hcp-pi-elitek"
          >
            Prescribing Information
          </a>
          , including Boxed WARNING, for ELITEK<sup>®</sup> (rasburicase).
        </div>
        <div className="pi hcp home">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/jevtana/jevtana.html"
            target="_blank"
            rel="noreferrer"
            id="hcp-pi-jevtana"
          >
            Prescribing Information
          </a>
          , including Boxed WARNING, for JEVTANA<sup>®</sup> (cabazitaxel)
          Injection.
        </div>
        <div className="pi hcp elitek">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/elitek/elitek.html"
            target="_blank"
            rel="noreferrer"
            id="hcp-pi-elitek"
          >
            Prescribing Information
          </a>
          , including Boxed WARNING.
        </div>
        <div className="pi hcp jevtana">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/jevtana/jevtana.html"
            target="_blank"
            rel="noreferrer"
            id="hcp-pi-jevtana"
          >
            Prescribing Information
          </a>
          ,<br /> including Boxed WARNING.
        </div>
        <div className="pi hcp sarclisa">
          Please see full{" "}
          <a
            href="https://products.sanofi.us/Sarclisa/sarclisa.pdf"
            target="_blank"
            rel="noreferrer"
            id="hcp-pi-sarclisa"
          >
            Prescribing Information
          </a>
          .
        </div>
      </div>
    ) : (
      ""
    )}
  </>
);

export default MidLink;