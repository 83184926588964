import SITELINKS from "./siteLinks.jsx"

const JEVTANANAV = [
    {
        content: "JEVTANA home",
        link: SITELINKS.jevtana
    },

    {
        content: "Enrollment",
        link: SITELINKS.jevtanaEnrollment
    },

    {
        content: "Financial assistance",
        link: SITELINKS.jevtanaFinancialAssistance
    },

    // {
    //     content: "Access & Reimbursement",
    //     link: SITELINKS.jevtanaReimbursement
    // },
    {
        content: "Access & reimbursement",
        link: SITELINKS.jevtanaReimbursement
    },
    {
        content: "Resource support",
        link: SITELINKS.jevtanaResourceSupport
    },
    {
        content: "FAQs",
        link: SITELINKS.jevtanaFAQs
    }
]

export default JEVTANANAV