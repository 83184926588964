// import React, {useEffect, useState} from "react";
import React from "react";
import classNames from "classnames";

import NavItem from "../item.jsx";

const BrandLinks = ({ activeNav, desktopOnly }) => {
  const getComponentClass = (link) => {
    if (
      window.location.pathname.toLowerCase().trim() ===
        link.toLowerCase().trim() ||
      window.location.pathname.toLowerCase().trim() ===
        link.toLowerCase().trim() + "/"
    ) {
      return "active";
    }
    return "";
  };

  return (
    <>
      {activeNav ? (
        <nav
          role="navigation"
          className={classNames("navigation low-nav", {
            "d-lg-block": desktopOnly,
          })}
        >
          <ul>
            {activeNav.map((navItem, index) => {
              if (navItem.link) {
                return (
                  <NavItem
                    componentclass={getComponentClass(navItem.link.path)}
                    navClass={navItem.class}
                    image={navItem.image}
                    internal={navItem.link.internal}
                    content={navItem.content}
                    imageSrc={navItem.imageSrc}
                    link={navItem.link.path}
                    // key={navItem}
                  />
                );
              }
            })}
          </ul>
        </nav>
      ) : null}
    </>
  );
};

export default BrandLinks;