import SITELINKS from "./siteLinks.jsx"

const PRIMARYNAV = [
    {
        content: "FOR HEALTHCARE PROFESSIONALS",
        link: SITELINKS.hcp,
        linkId: "hcp-link",
        id: "hcp-continue",
        class: "hcp",
        children: [
            {
            content: "<span class='upper'>Elitek</span><sup>®</sup> (rasburicase) ",
            link: SITELINKS.elitekHcp,
            class: "elitek-drop",
            id: "hcp-elitek-link"
            },
            {
            content: "<span class='upper'>Jevtana</span><sup>®</sup> (cabazitaxel)",
            link: SITELINKS.jevtanaHcp,
            class: "jevtana-drop",
            id: "hcp-jevtana-link"

            },
            {
            content: "<span class='upper'>Sarclisa</span><sup>®</sup> (isatuximab-irfc)",
            link: SITELINKS.sarclisaHcp,
            class: "sarclisa-drop",
            id: "hcp-sarclisa-link"
            },
        ] 
    },

    {
        content: "FOR PATIENTS AND CAREGIVERS",
        linkId: "patient-link",
        link: SITELINKS.home,
        class: "patients",
        children: [
            {
            content: `<span class='upper'>Elitek</span><sup>®</sup> (rasburicase)`,
            link: SITELINKS.elitek,
            class: "elitek-drop",
            id: "patient-elitek-link"

            },
            {
            content: "<span class='upper'>Jevtana</span><sup>®</sup> (cabazitaxel)",
            link: SITELINKS.jevtana,
            class: "jevtana-drop",
            id: "patient-jevtana-link"

            },
            {
            content: "<span class='upper'>Sarclisa</span><sup>®</sup> (isatuximab-irfc)",
            link: SITELINKS.sarclisa,
            class: "sarclisa-drop",
            id: "patient-sarclisa-link"

            },

        ]
    },
    // enroll a patient navigation
    // {
    //     content: "ENROLL A PATIENT",
    //     link: SITELINKS.enrollPatient,
    //     class: "epatient"
    // }

]

export default PRIMARYNAV;