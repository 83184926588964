/*

    TODO: Leave detailed instructions on how to store URLs and how to pull these URLs into pages.

*/

const SITELINKS = {
    home: {
        internal: true,
        path: "/",
        target: null
    },
    elitek: {
        internal: true,
        path: "/elitek",
        target: null
    },
    elitekEnrollment: {
        internal: true,
        path: "/elitek/enrollment",
        target: null
    },
    elitekFAQs: {
        internal: true,
        path: "/elitek/faqs",
        target: null
    },
    elitekFinancialAssistance: {
        internal: true,
        path: "/elitek/financial-assistance",
        target: null
    },
    elitekResourceRupport: {
        internal: true,
        path: "/elitek/resource-support",
        target: null
    },
    // elitekReimbursement: {
    //     internal: true,
    //     path: "/elitek/reimbursement",
    //     target: null
    // },
    jevtana: {
        internal: true,
        path: "/jevtana",
        target: null
    },
    jevtanaEnrollment: {
        internal: true,
        path: "/jevtana/enrollment",
        target: null
    },
    jevtanaFAQs: {
        internal: true,
        path: "/jevtana/faqs",
        target: null
    },
    jevtanaFinancialAssistance: {
        internal: true,
        path: "/jevtana/financial-assistance",
        target: null
    },
    jevtanaResourceSupport: {
        internal: true,
        path: "/jevtana/resource-support",
        target: null
    },
    // jevtanaReimbursement: {
    //     internal: true,
    //     path: "/jevtana/reimbursement",
    //     target: null
    // },
    sarclisa: {
        internal: true,
        path: "/sarclisa",
        target: null
    },
    sarclisaEnrollment: {
        internal: true,
        path: "/sarclisa/enrollment",
        target: null
    },
    sarclisaFAQs: {
        internal: true,
        path: "/sarclisa/faqs",
        target: null
    },
    sarclisaFinancialAssistance: {
        internal: true,
        path: "/sarclisa/financial-assistance",
        target: null
    },
    sarclisaResourceSupport: {
        internal: true,
        path: "/sarclisa/resource-support",
        target: null
    },
    // sarclisaReimbursement: {
    //     internal: true,
    //     path: "/sarclisa/reimbursement",
    //     target: null
    // },
    hcp: {
        internal: true,
        path: "/hcp",
        target: null
    },

    elitekHcp: {
        internal: true,
        path: "/hcp/elitek",
        target: null
    },
    elitekHcpEnrollment: {
        internal: true,
        path: "/hcp/elitek/enrollment",
        target: null
    },
    elitekHcpFAQs: {
        internal: true,
        path: "/hcp/elitek/faqs",
        target: null
    },
    elitekHcpFinancialAssistance: {
        internal: true,
        path: "/hcp/elitek/financial-assistance",
        target: null
    },
    elitekHcpReimbursement: {
        internal: true,
        path: "/hcp/elitek/reimbursement"
    },
    elitekHcpResourceSupport: {
        internal: true,
        path: "/hcp/elitek/resource-support"
    },

    jevtanaHcp: {
        internal: true,
        path: "/hcp/jevtana",
        target: null
    },
    jevtanaHcpEnrollment: {
        internal: true,
        path: "/hcp/jevtana/enrollment",
        target: null
    },
    jevtanaHcpFAQs: {
        internal: true,
        path: "/hcp/jevtana/faqs",
        target: null
    },
    jevtanaHcpFinancialAssistance: {
        internal: true,
        path: "/hcp/jevtana/financial-assistance",
        target: null
    },
    jevtanaHcpReimbursement: {
        internal: true,
        path: "/hcp/jevtana/reimbursement"
    },
    jevtanaHcpResourceSupport: {
        internal: true,
        path: "/hcp/jevtana/resource-support"
    },

    sarclisaHcp: {
        internal: true,
        path: "/hcp/sarclisa",
        target: null
    },
    sarclisaHcpEnrollment: {
        internal: true,
        path: "/hcp/sarclisa/enrollment",
        target: null
    },
    sarclisaHcpFAQs: {
        internal: true,
        path: "/hcp/sarclisa/faqs",
        target: null
    },
    sarclisaHcpFinancialAssistance: {
        internal: true,
        path: "/hcp/sarclisa/financial-assistance",
        target: null
    },
    sarclisaHcpReimbursement: {
        internal: true,
        path: "/hcp/sarclisa/reimbursement"
    },
    sarclisaHcpResourceSupport: {
        internal: true,
        path: "/hcp/sarclisa/resource-support"
    },
    libtayo: {
        internal: false,
        path: "https://www.libtayo.com/?gclid=CjwKCAiA78aNBhAlEiwA7B76pyEYqto_YTvEixQkaTugUHRsbqQvXQfOdei9lO1Y550PG5F9IKxN0xoCf2EQAvD_BwE",
        target: "_blank"
    },
    libtayoHCP: {
        internal: false,
        path: "https://www.libtayohcp.com/?gclid=CjwKCAiA78aNBhAlEiwA7B76pyEYqto_YTvEixQkaTugUHRsbqQvXQfOdei9lO1Y550PG5F9IKxN0xoCf2EQAvD_BwE",
        target: "_blank"
    },
    enrollPatient: {
        internal: false,
        path: "https://www.careassistproviderportal.com/careAssistProvider/s/login/?ec=302&startURL=%2FcareAssistProvider%2Fs%2F",
        target: "_blank"
    }

}


export default SITELINKS