import React from "react";
import { Link } from "gatsby";
import "./navigation.scss";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "open",
      activeSection: "Select medication",
    };
  }

  componentDidMount() {
    var dropdowns = document.getElementsByClassName("dropdown");
    for (let i = 0; i < dropdowns.length; i++) {
      let dropdown = dropdowns[i];
      dropdown.setAttribute("style", "height:" + dropdown.offsetHeight + "px");
      this.setState({ expanded: "closed" });
    }
    let activeDropdown = document.getElementsByClassName("active");
    if (typeof activeDropdown === "object") {
      activeDropdown = [].slice.call(activeDropdown);
      activeDropdown = activeDropdown.filter((value) => {
        return value.classList.contains("dropdown-item");
      });
      if (activeDropdown.length === 0) {
        let location = window.location.href.split("/");
        let sarclisaLocation = location.filter((value) => {
          if (value === "sarclisa") {
            activeDropdown = document.getElementsByClassName("sarclisa-drop");
          }
          return value === "sarclisa";
        });
        let jevtanaLocation = location.filter((value) => {
          if (value === "jevtana") {
            activeDropdown = document.getElementsByClassName("jevtana-drop");
          }
          return value === "jevtana";
        });
        let elitekLocation = location.filter((value) => {
          if (value === "elitek") {
            activeDropdown = document.getElementsByClassName("elitek-drop");
          }
          return value === "elitek";
        });
      }
      if (activeDropdown[0]) {
        this.setState({
          activeSection: activeDropdown[0].innerHTML,
        });
        // activeDropdown[0].parentElement.remove()
      }
    }
  }

  toggleNav = () => {
    this.state.expanded === "open"
      ? this.setState({ expanded: "closed" })
      : this.setState({ expanded: "open" });
  };

  render() {
    return (
      <li className="has-dropdown">
        <button onClick={this.toggleNav}>
          <span class="sr-only">Menu</span>
          <div className="hamburger-slice bar1"></div>
          <div className="hamburger-slice bar2"></div>
        </button>

        {this.props.internal ? (
          <nav
            to={this.props.link}
            activeClassName="active"
            aria-haspopup="true"
            className={"brands " + this.props.componentclass}
            id={this.props.componentId}
            target={this.props.target}
          >
            {this.props.content === this.state.activeSection ? (
              <span class="">{this.state.activeSection}</span>
            ) : (
              <span
                className="brand-selected"
                dangerouslySetInnerHTML={{ __html: this.state.activeSection }}
              ></span>
            )}{" "}
            <span className="icon icon-caret"></span>
          </nav>
        ) : (
          <a
            href={this.props.link}
            class={this.props.componentclass}
            id={this.props.componentId}
            target={this.props.target}
          >
            {this.props.image ? (
              <img alt={this.props.content} src={this.props.imagePath} />
            ) : (
              this.state.activeSection
            )}
          </a>
        )}

        <ul className={"dropdown " + this.state.expanded} aria-label="submenu">
          {this.props.children.map((child) =>
            child.link.internal ? (
              <li key={child.componentId}>
                <Link
                  to={child.link.path}
                  className={"dropdown-item" + " " + child.class}
                  activeClassName="active"
                  target={child.link.target}
                  id={child.componentId}
                  dangerouslySetInnerHTML={{ __html: child.content }}
                ></Link>
              </li>
            ) : (
              <li key={child.componentId}>
                <a
                  className={"dropdown-item" + " " + child.class}
                  href={child.link.path}
                  target={child.link.target}
                  id={child.componentId}
                  dangerouslySetInnerHTML={{ __html: child.content }}
                ></a>
              </li>
            )
          )}
        </ul>
      </li>
    );
  }
}

Dropdown.defaultProps = {
  internal: true,
  content: "Select medication",
  link: "",
  target: null,
  componentClass: "",
  children: [],
  image: null,
};

export default Dropdown;