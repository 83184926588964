import SITELINKS from "./siteLinks";

const SARCLISAHCPNAV = [

    {
        content: "SARCLISA home",
        link: SITELINKS.sarclisaHcp
    },

    {
        content: "Enrollment",
        link: SITELINKS.sarclisaHcpEnrollment
    },

    {
        content: "Financial assistance",
        link: SITELINKS.sarclisaHcpFinancialAssistance
    },

    {
        content: "Access & reimbursement",
        link: SITELINKS.sarclisaHcpReimbursement
    },
    {
        content: "Resource support",
        link: SITELINKS.sarclisaHcpResourceSupport
    },
    {
        content: "FAQs",
        link: SITELINKS.sarclisaHcpFAQs
    }

]


export default SARCLISAHCPNAV