import SITELINKS from "./siteLinks";

const JEVTANAHCPNAV = [

    {
        content: "JEVTANA home",
        link: SITELINKS.jevtanaHcp
    },
    {
        content: "Enrollment",
        link: SITELINKS.jevtanaHcpEnrollment
    },
    {
        content: "Financial assistance",
        link: SITELINKS.jevtanaHcpFinancialAssistance
    },

    {
        content: "Access & reimbursement",
        link: SITELINKS.jevtanaHcpReimbursement
    },
    {
        content: "Resource support",
        link: SITELINKS.jevtanaHcpResourceSupport
    },
    {
        content: "FAQs",
        link: SITELINKS.jevtanaHcpFAQs
    }

]

export default JEVTANAHCPNAV