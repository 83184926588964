import React from "react"
import "./footer.scss"
// import Modal from "../../components/modal/modal"
import sanofiLogo from "../../images/Unsorted-ASSETS/sanofi-logo.png"
import {getCode} from "../../data/pagesJobCodes"
import { Link } from "gatsby"

const checkBrandPage = (codeString) => {
  let codeStringArray = codeString.split('-');
  let jevtana = codeStringArray.includes("2209962") || codeStringArray.includes("2209965")
  if(jevtana){
    return "JEVTANA, "
  }
  let sarclisa = codeStringArray.includes("2209963") || codeStringArray.includes("2209969")
  if(sarclisa) return "SARCLISA, ";

  let elitek = codeStringArray.includes("2209964") || codeString.includes("2209972")
  if(elitek) return "ELITEK, ";

  return "JEVTANA, ELITEK, SARCLISA, "
}

const Footer = ({pageid,children}) => {

    const prefCenter =(e)=>{
      if (typeof window !== 'undefined' && window.OneTrust) {
        // Check if the OneTrust API for controlling the modal exists
        if (window.OneTrust.ToggleInfoDisplay) {
          // Toggle the cookie settings modal
          window.OneTrust.ToggleInfoDisplay();
        } else {
          console.error('OneTrust ToggleInfoDisplay API not available');
        }
      } else {
        console.error('OneTrust not available');
      }
      e.preventDefault();
    }

    return (
      <>
        <footer>
          <a
            className="sanofi-logo"
            href="https://www.sanofi.us/"
            target="_blank"
            rel="noreferrer"
            id="careassist-sanofi-logo"
          >
            <img src={sanofiLogo} alt="Sanofi Logo" />
          </a>
          <div className="footer-copy">
            <nav>
              <a
                href="https://www.sanofi.us/en/legal-notice/"
                target="_blank"
                rel="noreferrer"
                id="careassist-legal-disclaimer"
              >
                Legal Disclaimer
              </a>
              <a
                href="https://www.sanofi.com/en/sanofi-us-privacy-policies"
                target="_blank"
                rel="noreferrer"
                id="careassist-privacy-policy"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.sanofi.us/en/consumer-health-data-privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Consumer Health Data Privacy Policy
              </a>
              </nav>
              <nav style={{marginTop: `1rem`}}>  
              <a
                href="https://www.sanofi.us/en/contact-us"
                target="_blank"
                rel="noreferrer"
                id="careassist-contact-us"
              >
                Contact Us
              </a>
              <a
                href="#"
                id="ot-sdk-btn"
                onClick={(e)=>prefCenter(e)}
              >
                Do Not Sell or Share My Personal Information
              </a>
            </nav>
            <p className="pro-intend">
              <b>
                This site is intended for use by US healthcare professionals
                only.
              </b>
            </p>
            <p className="patient-intend">
              <b>This site is intended for use by US residents only.</b>
            </p>
            <p>
              &copy; 2023 sanofi-aventis U.S.&nbsp;LLC.
              All&nbsp;rights&nbsp;reserved. {(getCode(pageid)) ? checkBrandPage(getCode(pageid) ) : null}CareASSIST, and Sanofi are registered trademarks of Sanofi or an affiliate.
              <br />
              {getCode(pageid)}
            </p>
          </div>
          <div id="hcp-modal" className="modal">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header"></div>
                <div className="modal-body">
                  <h2>
                    This information is intended for US healthcare professionals only.
                  </h2>
                  <p>
                    Click the "CONTINUE" button below to go to the page you
                    requested, or the "CANCEL" button to return to the previous
                    page.
                  </p>
                </div>
                <div className="modal-footer">
                  <button id="hcp-modal-close" className="btn light-blue">
                    Cancel
                  </button>
                  <Link id="hcp-continue" className="btn light-blue" to="/hcp/">
                    Continue
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }

  export default Footer