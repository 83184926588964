import React, {useEffect, useCallback} from "react";
import Player from "@vimeo/player";

// images
import close from "../../images/close.png";

// styles
import "./video-modal.style.scss";

const VideoModal = ({videoSrc}) => {
    const handleClose = () => {
        const videoModal = document.getElementById("video-overlay");
        const videoIframe = document.getElementById("video-iframe");
        videoIframe.removeAttribute('data-ready');
        videoModal.classList.add("hide");
        document.querySelector("html").style.overflow = "auto";
        window.player.pause();
    };

    useEffect(() => {
        const docRef = window.document
        docRef.addEventListener("keydown", (e) => closeVideoModal(e), false);
        const videoIframe = document.getElementById("video-iframe");
        // vimeo player to setup hide on keypress
        const player = new Player(videoIframe);
        window.player = player;

        player.on('play', (e) => {
            playerListener('play');
        });

        player.on('pause', (e) => {
            playerListener('pause');
        });
    }, []);

    const playerListener = (trigger) => {
        const player = window.player;
        const pw = player.element.contentWindow;
        const _window = pw.parent.parent;
        _window.blur();
        _window.focus();
        _window.addEventListener("keydown", (e) => {
            if(trigger === 'play') {
                player.pause();
            }
            closeVideoModal(e);
        }, false);
    }

    const closeVideoModal = (e) => {
        const modal = document.querySelector('#video-overlay');
        if (e.key === "Escape" && !modal.classList.contains("hide")) {
            modal.classList.add("hide");
            document.querySelector('html').style.overflow = 'auto';
        }

    }

    return (
        <div id="video-overlay" className="hide" onClick={handleClose} >
            <div className="video-modal" >

                <p className="title"></p>
                <div className="video">
                    <button id="close-x-button" onClick={handleClose}>
                        <img src={close} alt=""/>
                    </button>
                    <iframe
                        id="video-iframe"
                        src={videoSrc}
                        frameBorder="0"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default VideoModal;
