import React from "react";
import PropTypes from "prop-types";
import Header from "../components/header/desktop/default/header.jsx";
import Footer from "../components/footer/footer.jsx";
import BackToTop from "../components/backToTop/BackToTop";
import VideoModal from "../components/videoModal/videoModal";
import "../styles/core.scss";
import "./layout.scss";
import Helmet from "react-helmet"
import Link from "gatsby";

import { mobileContext } from "../components/display/display.js";

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileContext: "desktop",
      indication : "",
      pathway: "",
      pageInView: "",
    };
  }

  containsKeywords() {
    // Ensure 'window' is available
    if (typeof window !== "undefined") {
      // Get the current URL
      const url = window.location.href.toLowerCase(); // Convert to lower case for case-insensitive matching
  
      // Check if the URL contains 'jevtana' or 'elitek'
      return url.includes("jevtana") || url.includes("elitek");
    }
    // Return false if 'window' is not available (e.g., during SSR)
    return false;
  };

  returnIndication(indication) {
    // Ensure 'window' is available
    if (typeof window !== "undefined") {
      // Get the current URL
      const url = window.location.href.toLowerCase(); // Convert to lower case for case-insensitive matching
  
      // Check if the URL contains 'jevtana' or 'elitek'
      return url.includes(indication);
    }
    // Return false if 'window' is not available (e.g., during SSR)
    return false;
  };

  returnisHcp() {
    // Ensure 'window' is available
    if (typeof window !== "undefined") {
      // Get the current URL
      const url = window.location.href.toLowerCase(); // Convert to lower case for case-insensitive matching
  
      // Check if the URL contains 'jevtana' or 'elitek'
      return url.includes("hcp");
    }
    // Return false if 'window' is not available (e.g., during SSR)
    return false;
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.checkRoute();
    this.hcpModal();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth <= 992) {
      this.setState({
        mobileContext: "mobile",
      });
    } else {
      this.setState({
        mobileContext: "desktop",
      });
    }
  };

  hcpModal = () => {
    document
      .getElementById("hcp-continue")
      .addEventListener("click", this.handleModalOpenLink);
    document
      .getElementById("hcp-modal-close")
      .addEventListener("click", this.handleModalCloseLink);
  };

  handleModalOpenLink = (event) => {
    event.preventDefault();
    this.handleModalOpen(event);
  };

  handleModalCloseLink = (event) => {
    event.preventDefault();
    this.handleModalClose(event);
  };

  handleModalOpen = (event) => {
    const modalTab = document.getElementById("hcp-continue");
    const isTabActive = modalTab.classList.contains("active-list-item");

    if (isTabActive) {
      return false;
    } else {
      document.getElementById("hcp-modal").classList.add("on");
    }
  };

  handleModalClose = (event) => {
    document.getElementById("hcp-modal").classList.remove("on");
  };

  handleHcpConfirmation = (event) => {
    document.getElementById("hcp-modal").classList.remove("on");
  };

  checkRoute = () => {
    let location = window.location.href

    location = window.location.href.split("/");

    let outerLayer = document.getElementById("outer-layer");

    let hcp = location.filter((value) => {
      return value === "hcp";
    });

    let jevtana = location.filter((value) => {
      return value === "jevtana";
    });

    let sarclisa = location.filter((value) => {
      return value === "sarclisa";
    });

    let elitek = location.filter((value) => {
      return value === "elitek";
    });

    let classOne = "";

    if (hcp.length > 0) {
      classOne = "hcp";
    } else {
      classOne = "patients";
    }

    if (classOne.length > 0) {
      if (jevtana.length > 0) {
        classOne += " jevtana brand-page";
      } else if (sarclisa.length > 0) {
        classOne += " sarclisa brand-page";
      } else if (elitek.length > 0) {
        classOne += " elitek brand-page";
      }
    }

    outerLayer.className = classOne;
    this.state.pageInView = classOne;
  };

  render() {
    return (
      <>
        <Helmet>
          <script
            src="https://cdn.cookielaw.org/consent/470a9f08-f20f-4528-8be3-9d3f4e36a4de-test/otSDKStub.js"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script="02e87c20-3e42-49b5-911d-08435cbadae0"
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `function OptanonWrapper(){ }`
            }}
          />
        </Helmet>
        {this.containsKeywords() &&
          <Helmet>
          {/* This sets a class on the body element */}
            <html className="body--lock" />
          </Helmet>
        }
        <div id="outer-layer">
          {/*

              The pageid prop is used to identify the page and allow you to target specific
              elements on specific pages via CSS.

              For example, the homepage's ID may be 'home'. You're asked to hide a
              particular global component on the homepage. The CSS selector will be as
              follows:

              #home .component { display: none; }

          */}
          <mobileContext.Provider value={this.state.mobileContext}>
            <div className={`outer-wrapper ${this.props.pageClass}`} id={this.props.pageid}>
              <Header />
              <article>{this.props.children}</article>
              {this.containsKeywords() && 
                <div className="sus-modal">
                  <div className="sus-modal__sneezeguard"></div>
                    <div className="sus-modal__modal">
                      <p className="sus-modal__heading">
                        As of May 2024, patient support services for&nbsp;
                        {this.returnisHcp() && this.returnIndication("jevtana") &&
                          <>
                           Jevtana<sup>&reg;</sup> (cabazitaxel)
                          </>
                        }
                        {!this.returnisHcp() && this.returnIndication("jevtana") &&
                          <>
                            Jevtana<sup>&reg;</sup> (cabazitaxel)
                          </>
                        }

                        {this.returnisHcp() && this.returnIndication("elitek") &&
                          <>
                            Elitek<sup>&reg;</sup> (rasburicase) 
                          </>
                        }
                        {!this.returnisHcp() && this.returnIndication("elitek") &&
                          <>
                            Elitek<sup>&reg;</sup> (rasburicase) 
                          </>
                        }
                        &nbsp;has transitioned from the CareASSIST<sup>&reg;</sup> patient support program to Sanofi Patient Connection (SPC). </p>
                      <p>
                        To learn more about resources and support, please call SPC at (888)-847-4877, Monday through Friday, 9:00 AM – 8:00 PM ET or visit <a href="https://www.sanofipatientconnection.com" target="_blank" rel="noreferrer">www.sanofipatientconnection.com</a>.
                      </p>
                      <p>
                         {this.returnisHcp() && this.returnIndication("jevtana") &&
                          <>
                            Please see full <a href="https://products.sanofi.us/jevtana/jevtana.html" target="_blank" rel="noreferrer">Prescribing Information</a>, including Boxed WARNING.
                          </>
                        }
                        {!this.returnisHcp() && this.returnIndication("jevtana") &&
                          <>
                            Please see full <a href="https://products.sanofi.us/jevtana/jevtana.html" target="_blank" rel="noreferrer">Prescribing Information</a>, including Boxed WARNING, and <a href="https://products.sanofi.us/Jevtana/Jevtana.html#section-21" target="_blank" rel="noreferrer">Patient Information</a>.
                          </>
                        }

                        {this.returnisHcp() && this.returnIndication("elitek") &&
                          <>
                            Please see full <a href="https://products.sanofi.us/elitek/elitek.html" target="_blank" rel="noreferrer">Prescribing Information</a>, including Boxed WARNING.
                          </>
                        }
                        {!this.returnisHcp() && this.returnIndication("elitek") &&
                          <>
                            Please see full <a href="https://products.sanofi.us/elitek/elitek.html" target="_blank" rel="noreferrer">Prescribing Information</a>, including Boxed WARNING.
                          </>
                        }
                      </p>
                      <div className="sus-modal__cta">
                        <a href="https://www.sanofipatientconnection.com" target="_blank" rel="noreferrer">Visit SPC</a>
                      </div>
                      <p className="mb-0">
                        {this.returnisHcp() && this.returnIndication("jevtana") &&
                          <small>MAT-US-2403367-v1.0-04/2024</small>
                        }
                        {!this.returnisHcp() && this.returnIndication("jevtana") &&
                          <small>MAT-US-2403370-v1.0-04/2024</small>
                        }

                        {this.returnisHcp() && this.returnIndication("elitek") &&
                          <small>MAT-US-2403366-v1.0-04/2024</small>
                        }
                        {!this.returnisHcp() && this.returnIndication("elitek") &&
                          <small>MAT-US-2403368-v1.0-04/2024</small>
                        }
                      </p>
                    </div>
                </div>
              }
              <Footer pageid={this.state.pageInView} />
            </div>
          </mobileContext.Provider>
          <VideoModal videoSrc="https://player.vimeo.com/video/790904069" />
        </div>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageid: PropTypes.node.isRequired,
};