import React from "react";
import classNames from "classnames";
import PRIMARYNAV from "../../../data/primaryNav.jsx";
import Dropdown from "../dropdown.jsx";


const DropDownBrand = ({ activeTab, desktopOnly }) => (
  <>
    <ul className={classNames("dd-brand", { "d-lg-block": desktopOnly })}>
      {PRIMARYNAV.map((navItem, index) => {
        if (activeTab === "patients" && navItem.class === "patients") {
          return (
            <Dropdown
              internal={navItem.link.internal}
              content={"Select medication"}
              link={""}
              children={navItem.children}
              image={navItem.image}
              componentId={navItem.children.id}
              componentclass="dd-container"
            />
          );
        } else if (activeTab === "hcp" && navItem.class === "hcp") {
          return (
            <Dropdown
              internal={navItem.link.internal}
              content={"Select medication"}
              link={""}
              children={navItem.children}
              image={navItem.image}
              componentId={navItem.children.id}
            />
          );
        }
      })}
    </ul>
  </>
);

export default DropDownBrand;