import SITELINKS from "./siteLinks.jsx"

const ELITEKNAV = [
    {
        content: "ELITEK home",
        link: SITELINKS.elitek
    },
    {
        content: "Enrollment",
        link: SITELINKS.elitekEnrollment
    },
    {
        content: "Financial assistance",
        link: SITELINKS.elitekFinancialAssistance
    },

    // {
    //     content: "Access & Reimbursement",
    //     link: SITELINKS.elitekReimbursement
    // },
    {
        content: "Resource support",
        link: SITELINKS.elitekResourceRupport
    },
    {
        content: "FAQs",
        link: SITELINKS.elitekFAQs
    }
]


export default ELITEKNAV