import SITELINKS from "./siteLinks.jsx"


const SARCLISANAV = [
    {
        content: "SARCLISA home",
        link: SITELINKS.sarclisa
    },

    {
        content: "Enrollment",
        link: SITELINKS.sarclisaEnrollment
    },

    {
        content: "Financial assistance",
        link: SITELINKS.sarclisaFinancialAssistance
    },

    // {
    //     content: "Access & Reimbursement",
    //     link: SITELINKS.sarclisaReimbursement
    // },
    {
        content: "Resource support",
        link: SITELINKS.sarclisaResourceSupport
    },
    {
        content: "FAQs",
        link: SITELINKS.sarclisaFAQs
    }
]

export default SARCLISANAV