const pagesJobCodes = {
        patients: {
            "home": "MAT-US-2209977-v1.0-01/2023",
            "elitek": "MAT-US-2209964-v1.0-01/2023",
            "jevtana": "MAT-US-2209962-v2.0-10/2023",
            "sarclisa": "MAT-US-2209963-V2.0-12/2023"
        },
        hcp: {
            "home": "MAT-US-2209976-v1.0-01/2023",
            "elitek": "MAT-US-2209972-v1.0-01/2023",
            "jevtana": "MAT-US-2209965-v2.0-10/2023",
            "sarclisa": "MAT-US-2209969-V2.0-12/2023"
    }
}

const getCode = (pageInView) => {
    const parts = pageInView.split(' ');
    const activeTab = parts[0] !== undefined ? parts[0] : 'patients';
    const activePage = parts[1] !== undefined ? parts[1] : 'home';
    const tabRecords = pagesJobCodes[activeTab];
    const jobCode = tabRecords !== undefined ? tabRecords[activePage] : '';
    return jobCode;
}

export {getCode, pagesJobCodes};