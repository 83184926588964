import React from "react";
import Nav from "../../../navigation/nav/nav";
import "./header.scss";
import { mobileContext } from "../../../display/display.js";
// import { Link } from "gatsby"

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = mobileContext;

  render() {
    return (
      <header>
        <Nav />
      </header>
    );
  }
}

export default Header;
