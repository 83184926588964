import SITELINKS from "./siteLinks.jsx"


const ELITEKHCPNAV = [

    {
        content: "ELITEK home",
        link: SITELINKS.elitekHcp
    },


    {
        content: "Enrollment",
        link: SITELINKS.elitekHcpEnrollment
    },

    {
        content: "Financial assistance",
        link: SITELINKS.elitekHcpFinancialAssistance
    },
    
    {
        content: "Access & reimbursement",
        link: SITELINKS.elitekHcpReimbursement
    },
    {
        content: "Resource support",
        link: SITELINKS.elitekHcpResourceSupport
    },
    {
        content: "FAQs",
        link: SITELINKS.elitekHcpFAQs
    }

]

export default ELITEKHCPNAV
