import React, {useState, useEffect} from "react"
import $ from "jquery"

import "./backToTop.scss"


const BackToTop = () => {
    const [floatBTTBtn, setFloatBTTBtn] = useState(false);

    const addBodyClass = () => {
        const body = document.querySelector("body");
        (floatBTTBtn) ? body.classList.add("has-btt-float") : body.classList.remove("has-btt-float");

    }

    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const checkScroll = () => {
        const scrollTop = $(window).scrollTop();
        const topDistance = 150;
        setFloatBTTBtn(topDistance > scrollTop ? false : true);
    }

    useEffect(() => {
        checkScroll();
        addBodyClass();
        window.addEventListener("scroll", checkScroll, {passive: false});
    }, [floatBTTBtn]);

    return (
        <>
            <div id="back-to-top" 
                 className={(floatBTTBtn && ("back-to-top show")) || "back-to-top"}><span onClick={topFunction}>Back to top</span>
            </div>
        </>
    )
}

export default BackToTop;